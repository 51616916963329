import { render, staticRenderFns } from "./Dealers.vue?vue&type=template&id=3bf2d410&scoped=true"
import script from "./Dealers.vue?vue&type=script&lang=js"
export * from "./Dealers.vue?vue&type=script&lang=js"
import style0 from "./Dealers.vue?vue&type=style&index=0&id=3bf2d410&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf2d410",
  null
  
)

export default component.exports