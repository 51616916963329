<template>
   <v-hover v-slot="{ hover }">
       <v-card
           class="pt-4 pr-4 pl-4 pb-1 dealerCard rounded-lg mr-2 ml-1"
           :class="hover ? 'theme--dark' : ''"
           :color="hover ? '#3D3533' : '#EBEAEA'"
           :elevation="hover ? 8 : 4"
           hover
           v-on:click="$emit('dealer-selection', dealer.id)"

       >
           <p class="centuryGothicBold nameText text-uppercase" :style="hover ? 'color: #FFF;' : 'color: #3D3533;'">{{ dealer.name }}</p>
           <p class="centuryGothic addressText mt-n2">{{ dealer.address.toUpperCase() }}</p>
       </v-card>
   </v-hover>
</template>

<script>
export default {
    name: "Dealer_Card",
    props: [
        'dealer'
    ],
    methods: {
    },
}
</script>

<style scoped>
.nameText {
    font-size: 15px;
}

.addressText {
    font-size: 12px;
}
</style>