export const statesList = [
  'Todo México',
  // 'Aguascalientes',
  // 'Baja California',
  // 'Baja California Sur',
  // 'Campeche',
  'Chiapas',
  // 'Chihuahua',
  'Ciudad de México',
  // 'Coahuila',
  'Colima',
  // 'Durango',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'Estado de México',
  'Michoacán',
  'Morelos',
  'Nayarit',
  // 'Nuevo León',
  // 'Oaxaca',
  'Puebla',
  'Querétaro',
  // 'Quintana Roo',
  // 'San Luis Potosí',
  // 'Sinaloa',
  // 'Sonora',
  // 'Tabasco',
  // 'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  // 'Yucatán',
  // 'Zacatecas'
];