<template>
    <v-container fluid>
        <!-- SCREEN TITLE -->
        <v-row align="center" class="mt-16 mb-n4 noSpace" justify="center">
            <p class="centuryGothicBold screenTitle">DISTRIBUIDORES</p>
        </v-row>

        <!-- SCREEN BODY -->
        <v-row class="mx-2">
            <!-- DEALER SELECTION WIDGET -->
            <v-col class="noSpace" id="dealerSelectionWidget" cols="12" md="3" lg="3" xl="3">
                <v-container class="pt-0" fluid id="dealerSelectionWidgetContainer">
                    <!-- STATE FILTER SELECTOR -->
                    <v-select
                        @change="filterDealersListPerState"
                        append-icon="mdi-chevron-down"
                        label="Selecciona un estado"
                        v-model="selectedState"
                        :items="statesList"
                        color="secondary"
                        outlined
                        dense
                    ></v-select>

                    <!-- DESKTOP DEALERS LIST -->
                    <v-container
                        id="dealersListContainer"
                        class="pa-0 mt-n4 hidden-sm-and-down"
                    >
                        <DynamicScroller
                            :items="dealersList"
                            :item-size="1"
                            key-field="id"
                        >
                            <DynamicScrollerItem
                                :size-dependencies="[ item.address ]"
                                slot-scope="{ item, index, active }"
                                :data-index="index"
                                :active="active"
                                :item="item"
                            >
                                <!-- DEALER CARD -->
                                <Dealer_Card
                                    v-on:dealer-selection="onDealerSelection"
                                    :dealer="item"
                                    :id="item.id"
                                    class="mb-4"
                                ></Dealer_Card>

                            </DynamicScrollerItem>
                        </DynamicScroller>
                    </v-container>

                    <!-- MOBILE DEALERS LIST -->
                    <v-container
                        id="dealersListContainerMobile"
                        class="pa-0 mt-n2 hidden-md-and-up"
                    >
                        <v-select
                            label="Selecciona un distribuidor"
                            append-icon="mdi-chevron-down"
                            :items="markersList"
                            color="secondary"
                            v-model="selectedDealer"
                            @change="mobileDealerSelection"
                            outlined
                            dense
                        >
                            <template slot="item" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                {{ data.item.name }}
                            </template>
                            <template slot="selection" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                {{ data.item.name }}
                            </template>
                            <template slot="value" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                {{ data.item.id }}
                            </template>
                        </v-select>
                    </v-container>
                </v-container>
            </v-col>

            <!-- DEALER DETAILS SECTION -->
            <v-col align-self="start" class="noSpace" id="dealerDetailsContainer" cols="12" md="9" lg="9" xl="9">
                <v-container fluid>
                    <v-row align="start" justify="start">
                        <!-- DEALERS LOCATION MAP -->
                        <gmaps-map :options="mapOptions" id="dealersLocationsMap">
                            <!-- DEALERS MAP MARKERS -->
                            <gmaps-marker
                                v-for="(dealer, index) in markersList"
                                :key="index"
                                :position="{lat: dealer.location.latitude, lng: dealer.location.longitude}"
                                @click="onDealerSelection(dealer.id)"
                            ></gmaps-marker>
                        </gmaps-map>

                        <!-- SELECT A DEALER TEXT -->
                        <v-row v-if="selectedDealer === null" justify="center" align="center">
                            <p class="centuryGothicBold screenTitle mt-10 selectDealerTitle" style="color: #BAB8B7">
                                SELECCIONA UN DISTRIBUIDOR
                            </p>
                        </v-row>

                        <!-- SELECTED DEALER DETAILS -->
                        <v-row v-if="selectedDealer !== null" align="start" class="px-2 pt-2 dealerDetailsRow" justify="space-between">
                            <!-- DEALER GENERAL INFO -->
                            <v-col cols="9" md="8">
                                <!-- DEALER NAME -->
                                <p class="centuryGothicBold" style="color: #3D3533; font-size: 1.25em;">
                                    {{ selectedDealerObject.name.toUpperCase() }}
                                </p>

                                <!-- DEALER ADDRESS -->
                                <p class="centuryGothic mt-n2" style="color: #3D3533; font-size: 1em;">
                                    {{ selectedDealerObject.address.toUpperCase() }}
                                </p>

                                <!-- DEALER PHONE NUMBERS -->
                                <p class="centuryGothic" style="color: #3D3533; font-size: 1em;">WhatsApp:
                                    {{ selectedDealerObject.whatsapp }}
                                </p>

                                <p class="centuryGothic mt-n4" style="color: #3D3533; font-size: 1em;">Teléfono:
                                    {{ selectedDealerObject.phone }}
                                </p>

                                <!-- DEALER CONTACT EMAIL -->
                                <p class="centuryGothic" style="color: #3D3533; font-size: 1em;">Mail:
                                    {{ selectedDealerObject.email }}
                                </p>
                            </v-col>

                            <!-- DEALER CTA BUTTONS -->
                            <v-col cols="3" md="4">
                                <v-row align="center" justify="center">
                                    <!-- GET DIRECTIONS BUTTON -->
                                    <v-col align="center" md="6" sm="12">
                                        <p class="centuryGothicBold ctaLabel">¿Cómo llegar?</p>

                                        <a :href="`https://www.google.pl/maps/dir//${selectedDealerObject.location.latitude},${selectedDealerObject.location.longitude}`"
                                           target="_blank">
                                            <v-btn
                                                class="mt-n2"
                                                color="primary"
                                                dark
                                                fab
                                                style="border-radius: 5px !important;"
                                            >
                                                <v-icon large>mdi-car</v-icon>
                                            </v-btn>
                                        </a>
                                    </v-col>

                                    <!-- PHONE CALL BUTTON -->
                                    <v-col align="center" md="6" sm="12">
                                        <p class="centuryGothicBold ctaLabel">Llamar ahora</p>

                                        <a :href="`tel:${selectedDealerObject.phone}`">
                                            <v-btn
                                                class="mt-n2"
                                                color="primary"
                                                dark
                                                fab
                                                style="border-radius: 5px !important;"
                                            >
                                                <v-icon large>mdi-phone</v-icon>
                                            </v-btn>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- AVAILABLE PRODUCTS TABLES -->
                        <v-row v-if="selectedDealer !== null" justify="center">
                            <v-col cols="12">
                                <v-row align="center" justify="center" class="noSpace">
                                    <p class="centuryGothicBold screenTitle">PRODUCTOS DISPONIBLES</p>
                                </v-row>
                            </v-col>

                            <!-- FAMILY AVAILABILITY TABLE -->
                            <v-col
                                v-for="(family, index) in selectedDealerObject.families"
                                :key="index" class="mx-4 mb-6"
                                transition="scale-transition"
                                cols="12"
                                md="5"
                            >
                                <!-- FAMILY TITLE -->
                                <v-row :class="family.id" align="center" class="pa-0" justify="center">
                                    <p class="centuryGothic white--text mt-1 mb-1 text-h6">FAMILIA {{ family.id.toUpperCase() }}</p>
                                </v-row>

                                <!-- PRODUCTS SIMPLE TABLE -->
                                <v-row class="pa-0">
                                    <v-simple-table dense style="width: 100%; padding: 0 !important;">
                                        <template v-slot:default>
                                            <!-- TABLE HEADER ROW -->
                                            <thead>
                                                <tr>
                                                    <th class="centuryGothicBold tableHeadLabels">PRODUCTOS</th>
                                                    <th class="centuryGothicBold tableHeadLabels">DISPONIBLE</th>
                                                </tr>
                                            </thead>

                                            <!-- TABLE BODY -->
                                            <tbody>
                                                <!-- TABLE PRODUCT ROWS -->
                                                <tr v-for="(product, index) in family.products" :key="index">
                                                    <td class="centuryGothic">{{ product.name.toString().toUpperCase() }}</td>
                                                    <td>
                                                        <!-- CHECKED CHECKBOX IF AVAILABLE -->
                                                        <v-icon v-if="product.available === true" color="secondary">
                                                            mdi-checkbox-marked
                                                        </v-icon>

                                                        <!-- EMPTY CHECKBOX IF NOT AVAILABLE -->
                                                        <v-icon v-else color="secondary">mdi-checkbox-blank-outline</v-icon>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-row>

                            </v-col>
                        </v-row>
                    </v-row>
                </v-container>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import {DynamicScroller, DynamicScrollerItem} from "vue-virtual-scroller";
import Dealer_Card from "../../components/cards/Dealer_Card";
import {gmapsMap, gmapsMarker} from "x5-gmaps";
import database from '../../constants/dealers_database.json';
import * as list from '../../constants/statesList'

export default {
    name: "Dealers",
    components: {
        DynamicScroller,
        DynamicScrollerItem,
        Dealer_Card,
        gmapsMap,
        gmapsMarker,
    },
    data() {
        return {
            selectedState: null,
            statesList: null,
            dealersList: null,
            markersList: null,
            originalDealersList: database['dealers'],
            selectedDealer: null,
            selectedDealerObject: database['dealers'][0],
            originalCoordinates: {
                // FULL MEXICO MAP
                center: {lat: 23.6887771, lng: -105.3488927},
                zoom: 4,
            },
            mapOptions: {
                center: {lat: 23.6887771, lng: -105.3488927},
                backgroundColor: "#EE403D",
                mapTypeControl: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
                streetViewControl: false,
                zoom: 4,
            },
        }
    },
    created() {
        // Tuve que hacer esto por un bug visual, no lo toquen jajaja
        this.dealersList = this.originalDealersList;
        this.markersList = this.originalDealersList;
        this.statesList = list.statesList;

        // Record Leads into Marketing Platforms
        window.gtag('event', 'find-location', {'send_to': 'AW-10845389810/xxxZCKyZwLUDEPKHvrMo'});
        window.fbq('track', 'FindLocation');
    },
    methods: {
        resetCoordinates() {
            this.mapOptions.center = this.originalCoordinates.center;
            this.mapOptions.zoom = this.originalCoordinates.zoom;
        },

        filterDealersListPerState() {
            let selection = this.selectedState;
            this.selectedDealer = null;
            this.dealersList = [];
            this.dealersList = this.originalDealersList;

            if (selection === "Todo México") {
                this.resetCoordinates();

                this.dealersList.forEach((value) => {
                    document.getElementById(value.id).style["display"] = "block";
                });

                this.markersList = this.originalDealersList;

            } else {
                this.resetCoordinates();

                let helperList = [];

                this.dealersList.forEach((dealer) => {
                    if (dealer.state !== selection) {
                        document.getElementById(dealer.id).style["display"] = "none";
                        document.getElementById(dealer.id).style["margin"] = '0';
                        document.getElementById(dealer.id).style["padding"] = '0';
                    } else {
                        document.getElementById(dealer.id).style["display"] = "block";
                        helperList.push(dealer);
                    }
                });

                this.markersList = helperList;
                // console.log("Markers List");
                // console.log(this.markersList);
            }
        },

        onDealerSelection: function (selection) {
            this.selectedDealer = selection;

            this.selectedDealerObject = this.originalDealersList.find(element => element.id === this.selectedDealer);

            const newCenter = {
                lat: this.selectedDealerObject.location.latitude,
                lng: this.selectedDealerObject.location.longitude,
            }

            this.mapOptions.zoom = 14;
            this.mapOptions.center = newCenter;
        },

        mobileDealerSelection(selection) {
            this.selectedDealer = selection;

            this.selectedDealerObject = this.originalDealersList.find(element => element.id === this.selectedDealer.id);

            // console.log(this.selectedDealerObject);


            const newCenter = {
                lat: this.selectedDealerObject.location.latitude,
                lng: this.selectedDealerObject.location.longitude,
            }

            this.mapOptions.zoom = 12;
            this.mapOptions.center = newCenter;
        }
    },
    metaInfo() {
        return {
            title: 'Mezcla Brava | Distribuidores',
            meta: [
                { vmid: 'description', name: 'description', content: 'Mezcla Brava es parte de la gran familia de GRUPO CALIDRA; la empresa con mayor tradición en la industria de la construcción y orgullosamente mexicanos.' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.mezclabrava.com/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.mezclabrava.com/' },
            ]
        }
    },
}
</script>

<style scoped>
.tableHeadLabels {
    color: #3D3533 !important;
    size: 1.5em !important;
}

.v-data-table {
    border-radius: 0 !important;
}

.rellena {
    background-color: #231F20;
}

.rellenaTable {
    border: 1px solid #231F20;
}

.levanta {
    background-color: #FF883C;
}

.levantaTable {
    border: 1px solid #FF883C;
}

.recubre {
    background-color: #0699CE;
}

.recubreTable {
    border: 1px solid #0699CD;
}

.coloca {
    background-color: #EE403D;
}

.colocaTable {
    border: 1px solid #EE403D;
}

#dealerSelectionWidget {
    position: fixed;
}

#dealerSelectionWidgetContainer {
    height: 75vh;
}

#dealersListContainer {
    height: 65vh;
    overflow-x: hidden;
}

#dealerDetailsContainer {
    left: 35%;
    position: sticky;
    padding: 0 0 0 20px !important;
}

#dealersLocationsMap {
    height: 60vh;
    width: 100%;
}

@media (max-width: 960px) {
    #dealerSelectionWidget {
        position: inherit;
    }

    #dealerSelectionWidgetContainer {
        height: auto;
    }

    #dealerDetailsContainer {
        padding: 0 !important;
        margin-top: -20px !important;
    }

    #dealersLocationsMap {
        height: 40vh;
        width: 100%;
    }

    .selectDealerTitle {
        font-size: 1.4em;
    }

    .dealerDetailsRow {
        margin-top: 10px !important;
    }

    .ctaLabel {
        font-size: 0.9em;
    }
}
</style>